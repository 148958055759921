// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/app/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/app/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/welcome",
        "name": "welcome",
        "icon": "smile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/app/src/pages/Welcome'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login' */'/app/src/pages/login'), loading: LoadingComponent}),
        "layout": false,
        "exact": true
      },
      {
        "path": "/apply",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__apply' */'/app/src/pages/apply'), loading: LoadingComponent}),
        "layout": false,
        "exact": true
      },
      {
        "path": "/account",
        "name": "账号管理",
        "icon": "SettingOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account' */'/app/src/pages/account'), loading: LoadingComponent}),
        "access": "canAccountManage",
        "exact": true
      },
      {
        "path": "/operation",
        "name": "操作记录",
        "icon": "RedditOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation' */'/app/src/pages/operation'), loading: LoadingComponent}),
        "access": "canOperationManage",
        "exact": true
      },
      {
        "path": "/kefu",
        "name": "客服会话信息统计",
        "icon": "UserSwitchOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__kefu' */'/app/src/pages/kefu'), loading: LoadingComponent}),
        "access": "canKefu",
        "exact": true
      },
      {
        "path": "/tag",
        "name": "分类管理",
        "icon": "SettingOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tag' */'/app/src/pages/tag'), loading: LoadingComponent}),
        "access": "canTag",
        "exact": true
      },
      {
        "path": "/banner",
        "name": "Banner管理",
        "icon": "SettingOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__banner' */'/app/src/pages/banner'), loading: LoadingComponent}),
        "access": "canBannerManage",
        "exact": true
      },
      {
        "path": "/episode",
        "name": "剧集管理",
        "icon": "SettingOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__episode' */'/app/src/pages/episode'), loading: LoadingComponent}),
        "hideChildrenInMenu": true,
        "access": "canEpisodeManage",
        "routes": [
          {
            "path": "/episode",
            "redirect": "/episode/management",
            "exact": true
          },
          {
            "path": "/episode/management",
            "name": "剧集管理-创作者端",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__episode__management' */'/app/src/pages/episode/management'), loading: LoadingComponent}),
            "hideInBreadcrumb": true,
            "exact": true
          },
          {
            "path": "/episode/item/:episodeId",
            "name": "分集管理-创作者端",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__episode__item-management' */'/app/src/pages/episode/item-management'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/episode-audit",
        "name": "剧集管理与审核",
        "icon": "VideoCameraOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__episode-audit' */'/app/src/pages/episode-audit'), loading: LoadingComponent}),
        "access": "canEpisodeAudit",
        "exact": true
      },
      {
        "path": "/tickets/:episodeId",
        "name": "观影券列表",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__item-ticket' */'/app/src/pages/item-ticket'), loading: LoadingComponent}),
        "access": "canTicket",
        "hideChildrenInMenu": true,
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/diversity-audit",
        "name": "分集管理与审核",
        "icon": "PlaySquareOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__diversity-audit' */'/app/src/pages/diversity-audit'), loading: LoadingComponent}),
        "access": "canDiversityAudit",
        "exact": true
      },
      {
        "path": "/dy-comment",
        "name": "剧集评论管理",
        "icon": "MessageFilled",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dy-comment' */'/app/src/pages/dy-comment'), loading: LoadingComponent}),
        "access": "canComment",
        "exact": true
      },
      {
        "path": "/sale",
        "name": "销售数据",
        "icon": "AccountBookOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sale' */'/app/src/pages/sale'), loading: LoadingComponent}),
        "access": "canSale",
        "exact": true
      },
      {
        "path": "/real-sale",
        "name": "实时销量",
        "icon": "PropertySafetyOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__real-sale' */'/app/src/pages/real-sale'), loading: LoadingComponent}),
        "access": "canSale",
        "exact": true
      },
      {
        "path": "/daily",
        "name": "数据日报",
        "icon": "BarChartOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__daily' */'/app/src/pages/daily'), loading: LoadingComponent}),
        "access": "canDaily",
        "exact": true
      },
      {
        "path": "/settle",
        "name": "结算数据",
        "icon": "LineChartOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settle' */'/app/src/pages/settle'), loading: LoadingComponent}),
        "access": "canSettle",
        "exact": true
      },
      {
        "path": "/sell-data",
        "name": "销售数据",
        "icon": "AreaChartOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sell-data' */'/app/src/pages/sell-data'), loading: LoadingComponent}),
        "access": "canSettle",
        "exact": true
      },
      {
        "path": "/recommend",
        "name": "推荐池管理",
        "icon": "PieChartOutlined",
        "footerRender": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__recommend-pool' */'/app/src/pages/recommend-pool'), loading: LoadingComponent}),
        "access": "canPool",
        "exact": true
      },
      {
        "path": "/index.html",
        "redirect": "/welcome",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/welcome",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
