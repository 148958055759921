import { request } from 'umi';
import { message } from 'antd';
import type * as Res from '@/types';

/**
 * 用户登录
 * 登录态有效期2小时，
 * 过期后session失效，再次调用需要鉴权接口会返回code=5401
 * 不传参数为静默登录
 * @param data
 * @returns
 */
export function login(data?: {
  phone: string;
  verifyCode: string;
  rememberMe: boolean;
}): Promise<Res.Result<Res.CurrentUser>> {
  return request('/v1/goodsadminserver/auth/login', {
    method: 'POST',
    data: data || {},
    errorHandler(error) {
      console.log(JSON.stringify(error));
      const { code, errmsg } = error.response as any;
      if (code !== 803) {
        message.error(errmsg || '请求有误，请稍后再试');
      }
    },
  });
}

/**
 * 用户mailgo邮箱登录
 * @param data
 * @returns
 */
export function autoMailgoLogin(data?: {
  code: string;
  state: string;
}): Promise<null> {
  return request('/v1/goodsadminserver/auth/mailgo/login', {
    method: 'GET',
    params: data,
  });
}

// 使登录cookies过期，仅测试环境可用
export function invalidAccessToken(data: {
  accountId: String;
}): Promise<Res.Result<Res.CurrentUser>> {
  return request('/v1/goodsadminserver/auth/testOnly/invalidAccessToken', {
    method: 'POST',
    data,
  });
}

// getCsrfToken
export function getCsrfToken(): Promise<null> {
  return request('/v1/goodsadminserver/auth/getCsrfToken', {
    method: 'GET',
  });
}

/**
 * 发送短信
 *
 * @param data
 * @returns
 */
export function sendVerify(data: {
  phone: string;
  validate: string;
}): Promise<Res.Result> {
  return request('/v1/goodsadminserver/auth/sendVerifyCode', {
    method: 'POST',
    data,
  });
}

/**
 * 退出登录
 *
 * @returns
 */
export function logout(): Promise<Res.Result> {
  return request('/v1/goodsadminserver/auth/logout', {
    method: 'POST',
    data: {},
  });
}

/**
 * 获取登录用户信息
 */
export function getMineInfo(): Promise<Res.Result<Res.CurrentUser>> {
  return request('/v1/goodsadminserver/auth/userInfo', {
    method: 'GET',
  });
}

/**
 * 添加创作者信息
 *
 * @param data
 * @returns
 */
export function addMerchantInfo(data: {
  id: string;
  icon: string;
  nick: string;
  idNumber: string;
  realName: string;
  douYinId: string;
  frontImg: string;
  backImg: string;
}): Promise<Res.Result> {
  return request('/v1/goodscms/dasong/account/manage/add-merchant-info', {
    method: 'POST',
    data,
  });
}

/**
 * 获取NOS令牌(带类型)
 * @param data
 * @param options
 * @returns
 */
export function getNosToken(
  data: Array<{
    contentType: string;
    fileSize: number;
    suffix?: string;
  }>,
  options?: Record<string, any>
) {
  return request('/v1/goodscms/dasong/nos/getToken', {
    method: 'POST',
    data,
    ...options,
  });
}

/**
 * 获取cc令牌
 * @param data
 * @param options
 * @returns
 */
export function getCCToken(options?: Record<string, any>) {
  return request('/v1/goodscms/dasong/cc/getCCToken', {
    method: 'POST',
    data: {},
    ...options,
  });
}

/**
 * 获取cc付费视频的播放凭证token，必须登录
 *
 * @param options.mediaId
 * @returns
 */
export function fetchCCVideoToken(data: { mediaId: string }): Promise<any> {
  return request('/v1/goodscms/dasong/cc/getToken', {
    method: 'POST',
    data,
  });
}
