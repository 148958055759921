import type { RcFile } from 'antd/lib/upload';
// 获取圈子ID，取自于URL
export const getSquareIdByURL = () => {
  // 非圈子路由默认为 HOME
  if (
    !new RegExp(/^\/home\/square\/setting(\/.*)*$/g).test(
      window.location?.pathname
    )
  ) {
    return 'HOME';
  }
  const regx = /(?<=^\/home\/square\/setting\/)[\w]+/g;
  const res = window.location?.pathname?.match(regx);
  if (res?.length) {
    return res[0];
  }
  throw new Error("'获取圈子ID失败!");
};

/**
 * 获取图片文件的宽高
 */
export function getImageFileWidthAndHeigt(
  file: RcFile
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = () => {
        reject();
      };
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          img.src = reader.result as string;
        },
        false
      );
      reader.addEventListener('error', reject, false);
      reader.readAsDataURL(file);
    } catch (error) {
      reject();
    }
  });
}

/**
 * 获取HTTP请求前缀域名
 */
export const getPrefix = (): string => {
  if (process.env.UMI_APP_DEPLOY_ENV === 'production') {
    return 'https://inf-studio-admin.ds.163.com';
  }

  if (
    ['staging', 'testing', ''].includes(
      (process.env.UMI_APP_DEPLOY_ENV as string) || ''
    )
  ) {
    return 'https://inf-test-studio-admin.ds.163.com';
  }
  return '';
};

/**
 * 获取HTTP请求前缀域名
 */
export const getCcPrefix = (): string => {
  if (process.env.UMI_APP_DEPLOY_ENV === 'production') {
    return 'https://api.cc.163.com';
  }

  if (
    ['staging', 'testing', ''].includes(
      (process.env.UMI_APP_DEPLOY_ENV as string) || ''
    )
  ) {
    return 'https://api.dev.cc.163.com';
  }
  return '';
};

/**
 * 获取旧版CMS href
 */
export const getOldCmsHref = (): string => {
  if (
    ['staging', 'testing'].includes(process.env.UMI_APP_DEPLOY_ENV as string)
  ) {
    return `${getPrefix()}/cms_test`;
  }
  return `${getPrefix()}/cms`;
};

/**
 * 使nos img url携带缩略参数，默认为thumbnail=100x0
 */
export const getFormatNosImgUrl = (
  url: string,
  config?: { width?: number; height?: number }
) => {
  if (!url || url?.includes('.gif')) return url;
  const tempUrl = new URL(url);
  const { width = 100, height = 0 } = config || {};
  if (!tempUrl?.search?.includes('imageView')) {
    tempUrl?.searchParams.append('imageView', '');
  }
  tempUrl?.searchParams.append('thumbnail', `${width}x${height}`);
  return tempUrl.toString();
};
/**
 * 获取生产环境跟测试环境地址
 */
export const getAdminUrlPrefix = (): string => {
  if (process.env.UMI_APP_DEPLOY_ENV === 'production') {
    return 'https://studio.cc.163.com';
  }

  if (
    ['staging', 'testing', ''].includes(
      (process.env.UMI_APP_DEPLOY_ENV as string) || ''
    )
  ) {
    return 'https://studio.dev.cc.163.com';
  }
  return '';
};
/**
 * 获取生产环境跟测试环境邮箱登陆的client_id
 */
export const getClientId = (): string => {
  if (process.env.UMI_APP_DEPLOY_ENV === 'production') {
    return '302a28e2c19311eea0a10242ac120002';
  }

  if (
    ['staging', 'testing', ''].includes(
      (process.env.UMI_APP_DEPLOY_ENV as string) || ''
    )
  ) {
    return '22bfe4f8bb7b11eeaa9d0242ac120002';
  }
  return '';
};

/**
 * 获取url参数
 */
export const getUrlParam = (
  name: string,
  url: string = window.location.href
): string | null => {
  const pattern = new RegExp(`[?|&|#]${name}=([^&|#]+)`, 'g');
  const [, matchedValue] = pattern.exec(url) || []; // 使用数组解构赋值
  let items: string | null = null;

  if (matchedValue !== undefined) {
    try {
      items = decodeURIComponent(matchedValue);
    } catch (e) {
      console.log(e);
      try {
        items = decodeURIComponent(matchedValue);
      } catch (error) {
        console.log(error);
        items = matchedValue;
      }
    }
  }
  return items;
};
