import appServices, { McnListType } from '@/services/mini-app';
import {
  roleOption,
  MERCHANT_MANAGER_OPTIONS,
} from '@/pages/account/constants';
import { useModel } from 'umi';
import { CurrentUser } from '@/types';

// 返回获取到的mcn机构列表
const UseFetchMcnList = () => {
  return new Promise((resolve, reject) => {
    appServices
      .mcnListFetch()
      .then(({ code, result }) => {
        // console.log(result, 'mcn 机构列表');
        if (code === 200 && result.length) {
          const updatedAppList = result.map((i: McnListType) => ({
            ...i,
            label: i.name,
            value: i.id,
          }));
          if (updatedAppList.length > 1) {
            updatedAppList.unshift({
              id: '',
              value: '',
              label: '全部',
              name: '全部',
            });
          }
          resolve(updatedAppList);
        } else {
          resolve([]);
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

// 获取身份权限列表
const GetRoleList = () => {
  const { initialState } = useModel('@@initialState');
  const { currentUser } = initialState ?? {};
  const { role } = currentUser as CurrentUser;
  // 机构管理员返回创作者跟管理员
  if (role === 'MERCHANT_MANAGER') {
    return MERCHANT_MANAGER_OPTIONS;
  }
  // 超管返回全部
  if (role === 'MANAGER') {
    return roleOption;
  }
  return roleOption;
};

export { UseFetchMcnList, GetRoleList };
