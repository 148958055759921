// 易盾id
export const CAPTCHA_ID = '0134c4d0ed214a7baacfb102dfa3f0db';

// 大神默认头像
export const DS_AVATAR =
  'https://img.166.net/gameyw-misc/opd/squash/20230710/101341-patn6lk5c7.png';

// 登录用户id
export const SESSION_USER_ID = 'DS_STUDIO_CMS_USER_ID';

// 登录用户昵称
export const SESSION_USER_NICK = 'DS_STUDIO_CMS_USER_NICK';

// 承诺书示意图及文档
export const USER_LETTER_PIC =
  'https://cc.fp.ps.netease.com/file/66794673e7832b4b67778648Y4SdtTys05';
export const USER_LETTER_DOC =
  'https://cc.fp.ps.netease.com/file/667940511d11ec381f7f155fAqCtPRHA05';

// 成本配置比例情况 图以及文档
export const USER_RADIO_PIC =
  'https://cc.fp.ps.netease.com/file/6679468bb0ca2d21667231f5MrUWbg2H05';
export const USER_RADIO_DOC =
  'https://cc.fp.ps.netease.com/file/6679405143c6cafdfe8f6f9dtRuv9dRf05';
