// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import RedditOutlined from '@ant-design/icons/RedditOutlined';
import UserSwitchOutlined from '@ant-design/icons/UserSwitchOutlined';
import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined';
import PlaySquareOutlined from '@ant-design/icons/PlaySquareOutlined';
import MessageFilled from '@ant-design/icons/MessageFilled';
import AccountBookOutlined from '@ant-design/icons/AccountBookOutlined';
import PropertySafetyOutlined from '@ant-design/icons/PropertySafetyOutlined';
import BarChartOutlined from '@ant-design/icons/BarChartOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined';
import AreaChartOutlined from '@ant-design/icons/AreaChartOutlined';
import PieChartOutlined from '@ant-design/icons/PieChartOutlined'

export default {
  SmileOutlined,
SettingOutlined,
RedditOutlined,
UserSwitchOutlined,
VideoCameraOutlined,
PlaySquareOutlined,
MessageFilled,
AccountBookOutlined,
PropertySafetyOutlined,
BarChartOutlined,
LineChartOutlined,
AreaChartOutlined,
PieChartOutlined
}
    