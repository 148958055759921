import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

function isValidRate(rate: number) {
  return rate >= 0 && rate <= 1;
}

// staging 和 production 环境开启 sentry
export default function setupSentry() {
  const ENABLE_SENTRY = process.env.UMI_APP_DEPLOY_ENV;
  if (!ENABLE_SENTRY) return;

  let sampleRate = 1;
  let tracesSampleRate = 0.1;
  const IS_PRODUCTION = process.env.UMI_APP_DEPLOY_ENV === 'production';

  // production 环境需要考虑 CI 注入的采样率
  if (IS_PRODUCTION) {
    const CI_SAMPLE_RATE = parseFloat(
      process.env.UMI_APP_SENTRY_SAMPLE_RATE as string
    );

    const CI_TRACES_SAMPLE_RATE = parseFloat(
      process.env.UMI_APP_SENTRY_TRACES_SAMPLE_RATE as string
    );

    if (isValidRate(CI_SAMPLE_RATE)) {
      sampleRate = CI_SAMPLE_RATE;
    }

    if (isValidRate(CI_TRACES_SAMPLE_RATE)) {
      tracesSampleRate = CI_TRACES_SAMPLE_RATE;
    }
  }

  Sentry.init({
    dsn: process.env.UMI_APP_SENTRY_DSN,
    release: process.env.UMI_APP_COMMIT_SHA,
    environment: process.env.UMI_APP_DEPLOY_ENV,
    ignoreErrors: ['gmbridge_'],
    integrations: [
      new Integrations.BrowserTracing({
        traceXHR: false,
        traceFetch: false,
      }),
    ],
    sampleRate,
    tracesSampleRate,
  });

  Sentry.configureScope((scope) => {
    // 用于邮件告警
    scope.setTag('trace', process.env.UMI_APP_COMMIT_SHA);
  });
}
