import { history, request as requestObj } from 'umi';
import type { RequestConfig, RunTimeLayoutConfig } from 'umi';
import { PageLoading } from '@ant-design/pro-components';
import type { Settings as LayoutSettings } from '@ant-design/pro-components';
import queryString from 'query-string';
import { cloneDeep } from 'lodash';
import cookies from 'js-cookie';
import Footer from '@/components/Footer';
import RightContent from '@/components/RightContent';
import { login, getMineInfo as queryCurrentUser } from '@/services/base';
import { setSessionUser, removeSessionUser } from '@/helper/session-store-user';
import { UserState, CurrentUser, Role } from '@/types';
import moment from 'moment';
import { UseFetchMcnList } from '@/hooks/getMcnList';
import { McnListType } from '@/services/mini-app';
import defaultSettings from '../config/defaultSettings';
import setupSentry from './setup-sentry';
import { getCcPrefix } from './utils/get';

const loginPath = '/login';
const applyPath = '/apply';

setupSentry();

const momentLocal = moment().localeData();
// 重写moment localdata 方法，本地中文化
(momentLocal.monthsShort as Function) = () => [
  '1月',
  '2月',
  '3月',
  '4月',
  '5月',
  '6月',
  '7月',
  '8月',
  '9月',
  '10月',
  '11月',
  '12月',
];
(momentLocal.weekdaysMin as Function) = () => [
  '日',
  '一',
  '二',
  '三',
  '四',
  '五',
  '六',
];

/**
 * 检查登录用户状态
 *
 * @param params
 * @returns
 */
function checkUserState(params: { user?: CurrentUser }) {
  const { user } = params;
  const { location } = history;
  const { pathname } = location;

  if (
    [
      UserState.INITIALIZE,
      UserState.UNDER_REVIEW,
      UserState.REVIEW_NOT_PASSED,
    ].includes(user?.state as any)
  ) {
    if (pathname !== applyPath) {
      history.push(applyPath);
    }
    return user;
  }

  if (user?.state === UserState.NORMAL) {
    if ([applyPath, loginPath].includes(pathname)) {
      history.replace('/');
    }
    return user;
  }

  // 封禁或者其他情况
  if (pathname !== loginPath) {
    history.push(loginPath);
  }

  return undefined;
}

// 检测没有mcnlist 就要重新取
async function checkMcnList(
  { mcnList }: { mcnList: McnListType[] | unknown },
  setInitialState: (setInitialState: any) => void
) {
  const { location } = history;
  const { pathname } = location;
  if (pathname === loginPath || (mcnList as McnListType[])?.length > 0) return;
  try {
    const mcnListAjax = await UseFetchMcnList();
    setInitialState((s: any) => ({ ...s, mcnList: mcnListAjax }));
  } catch (e) {
    console.log(e, '初始化请求接口错误');
  }
}
/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings> & {
    siderWidth?: number;
  };
  currentUser?: CurrentUser;
  mcnList?: McnListType[];
  loading?: boolean;
  fetchUserInfo?: () => Promise<any>;
}> {
  const fetchUserInfo = async () => {
    try {
      const res = await queryCurrentUser();
      if (res.code !== 200) {
        throw new Error(res.errmsg);
      }

      setSessionUser(res.result as any);

      return checkUserState({
        user: res.result,
      });
    } catch (error) {
      history.push(loginPath);
    }
    removeSessionUser();
    return undefined;
  };

  let mcnList: McnListType[] | unknown = [];
  let currentUser: any = {};
  try {
    mcnList = await UseFetchMcnList();
    currentUser = await fetchUserInfo();
  } catch (e) {
    console.log(e, '初始化请求接口错误');
  }
  return {
    fetchUserInfo,
    currentUser,
    settings: defaultSettings,
    mcnList: mcnList as McnListType[],
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({
  initialState,
  setInitialState,
}) => {
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    waterMarkProps: {
      content: '',
    },
    footerRender: () => <Footer />,
    onPageChange: () => {
      checkUserState({
        user: initialState?.currentUser,
      });
      checkMcnList({ mcnList: initialState?.mcnList }, setInitialState);
    },
    links: [],
    menuHeaderRender: undefined,
    menuDataRender: (routes: any[]) => {
      routes.forEach((item) => {
        if (
          item.path === '/episode-audit' ||
          item.path === '/diversity-audit'
        ) {
          if (initialState?.currentUser?.role === Role.MANAGER) {
            // eslint-disable-next-line no-param-reassign
            item.name += '-管理员侧';
          } else if (initialState?.currentUser?.role === Role.REVIEWER) {
            // eslint-disable-next-line no-param-reassign
            item.name += '-审核员侧';
          }
        }
      });
      return routes;
    },
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    ...initialState?.settings,
  };
};

let hasReloginFail = false;
// 请求的全局设置
export const request: RequestConfig = {
  prefix: getCcPrefix(),
  errorConfig: {
    adaptor: (resData, ctx) => {
      const codeMaps = {
        403: '用户得到授权，但是访问是被禁止的。',
        404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
        500: '服务器发生错误，请检查服务器。',
        502: '网关错误。',
        503: '服务不可用，服务器暂时过载或维护。',
        504: '网关超时。',
      };
      return {
        success:
          ctx?.res?.status === 200 ||
          resData?.code === 200 ||
          ctx?.res instanceof Blob,
        errorMessage:
          codeMaps[ctx?.res?.status] || resData.errmsg || resData.message,
      };
    },
  },
  credentials: 'include',
  requestInterceptors: [
    // @ts-ignore
    async (url, option) => {
      const newOption = cloneDeep(option);
      if (!newOption.headers) {
        newOption.headers = {};
      }
      newOption.headers['GL-X-XSRF-TOKEN'] =
        (await cookies.get('NTES-XSRF-TOKEN')) || '';
      return { url, options: newOption };
    },
  ],
  responseInterceptors: [
    async (response, options) => {
      const { status } = response;
      const { responseType } = options;

      if (
        status >= 200 &&
        status <= 304 &&
        (!responseType || responseType === 'json')
      ) {
        const data = await response.clone()?.json?.();
        if (data?.code === 5401) {
          // 判断是否有长期cookies 在决定是否进行login续期
          if (!hasReloginFail) {
            const loginRes = await login();
            if (loginRes?.code === 200) {
              hasReloginFail = false;
              const resp = await requestObj(options.url, options);
              return resp;
            }
          }

          hasReloginFail = true;
          const { pathname } = history.location;
          history.replace({
            pathname: '/login',
            search: queryString.stringify({
              redirect: pathname,
            }),
          });
        }
      }
      return response;
    },
  ],
};
