// @ts-ignore
import { request } from 'umi';

export type AppListType = {
  appId: string; // id
  appName: string;
};
export type McnListType = {
  id: string; // id
  value: string;
  label: string; // id
  name: string;
};

/**
 * 小程序列表
 * @returns AppListType
 */
async function appListFetch(data?: { channel: 'WEI_XIN' | 'DOU_YIN' }) {
  return request('/v1/goodscms/dasong/cc/app/list', {
    method: 'GET',
    params: data,
  });
}
/**
 * mcn 机构列表
 * @returns McnListType
 */
async function mcnListFetch() {
  return request('/v1/goodscms/dasong/cc/mcn/list', {
    method: 'GET',
  });
}

export default { appListFetch, mcnListFetch };
