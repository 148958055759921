import { stringify } from 'querystring';
import React, { useCallback, useMemo } from 'react';
import { history, useModel } from 'umi';
import { Avatar, Menu, Spin } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import type { MenuInfo } from 'rc-menu/lib/interface';
import { removeSessionUser } from '@/helper/session-store-user';
import { DS_AVATAR } from '@/constants';
import { logout } from '@/services/base';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';

export type GlobalHeaderRightProps = {
  menu?: boolean;
};

/**
 * 退出登录，并且将当前的 url 保存
 */
const loginOut = async () => {
  await logout();
  removeSessionUser();
  const { query = {}, search, pathname } = history.location;
  const { redirect } = query;
  // Note: There may be security issues, please note
  if (pathname !== '/login' && !redirect) {
    history.replace({
      pathname: '/login',
      search: stringify({
        redirect: pathname + search,
      }),
    });
  }
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = () => {
  const { initialState, setInitialState } = useModel('@@initialState');

  const { currentUser } = initialState || {};

  const avatar = useMemo(() => {
    return `${
      currentUser?.merchantInfo?.icon || DS_AVATAR
    }?imageView&tostatic=0&thumbnail=100y100`;
  }, [currentUser?.merchantInfo?.icon]);

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event;
      if (key === 'logout') {
        setInitialState((s) => ({ ...s, currentUser: undefined }));
        loginOut();
      }
    },
    [setInitialState]
  );

  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  if (!currentUser || !currentUser.id) {
    return loading;
  }

  const menuHeaderDropdown = (
    <Menu
      className={styles.menu}
      selectedKeys={[]}
      onClick={onMenuClick}
      items={[
        {
          key: 'logout',
          icon: <LogoutOutlined />,
          label: '退出登录',
        },
      ]}
    />
  );

  // 管理员和审核员不需要填资料，所以会没有头像等信息
  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        <Avatar
          size="small"
          className={styles.avatar}
          src={avatar}
          alt="avatar"
        />
        <span className={`${styles.name} anticon`}>{currentUser.phone}</span>
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
