import { CurrentUser, Role } from './types';
/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(
  initialState: { currentUser?: CurrentUser } | undefined
) {
  const checkUserAccess = (accessType: string) => {
    const { currentUser } = initialState ?? {};
    return currentUser?.role === accessType;
  };

  return {
    canAccountManage:
      checkUserAccess(Role.MANAGER) ||
      checkUserAccess(Role.MERCHANT_MANAGER) ||
      checkUserAccess(Role.REVIEWER_MANAGER),
    canOperationManage: checkUserAccess(Role.MANAGER),
    canKefu: checkUserAccess(Role.MANAGER),
    canTag:
      checkUserAccess(Role.MANAGER) || checkUserAccess(Role.MERCHANT_MANAGER),
    canBannerManage:
      checkUserAccess(Role.MANAGER) || checkUserAccess(Role.MERCHANT_MANAGER),
    canEpisodeAudit:
      checkUserAccess(Role.MANAGER) ||
      checkUserAccess(Role.MERCHANT_MANAGER) ||
      checkUserAccess(Role.REVIEWER) ||
      checkUserAccess(Role.REVIEWER_MANAGER),
    canDiversityAudit:
      checkUserAccess(Role.MANAGER) ||
      checkUserAccess(Role.MERCHANT_MANAGER) ||
      checkUserAccess(Role.REVIEWER) ||
      checkUserAccess(Role.REVIEWER_MANAGER),
    canDaily:
      checkUserAccess(Role.MANAGER) || checkUserAccess(Role.MERCHANT_MANAGER),
    canSettle:
      checkUserAccess(Role.MANAGER) || checkUserAccess(Role.MERCHANT_MANAGER),
    canEpisodeManage: checkUserAccess(Role.MERCHANT),
    canSale: checkUserAccess(Role.MERCHANT),
    canComment:
      checkUserAccess(Role.MANAGER) ||
      checkUserAccess(Role.MERCHANT_MANAGER) ||
      checkUserAccess(Role.MERCHANT),
    canTicket: checkUserAccess(Role.MANAGER) || checkUserAccess(Role.MERCHANT),
    canPool: checkUserAccess(Role.MANAGER),
  };
}
