export enum Role {
  MERCHANT = 'MERCHANT', // 商户
  MANAGER = 'MANAGER', // 管理员
  REVIEWER = 'REVIEWER', // 审核
  REVIEWER_MANAGER = 'REVIEWER_MANAGER', // 审核管理员
  MERCHANT_MANAGER = 'MERCHANT_MANAGER', // 机构管理员
}

export enum UserState {
  NORMAL = 'NORMAL', // 正常用户
  DISABLED = 'DISABLED', // 封禁用户
  UNDER_REVIEW = 'UNDER_REVIEW', // 资料审核中
  REVIEW_NOT_PASSED = 'REVIEW_NOT_PASSED', // 资料审核不通过
  INITIALIZE = 'INITIALIZE', // 资料未填写
}

export type CurrentUser = {
  id: string;
  merchantInfo: {
    icon: string;
    nick: string;
  };
  phone: string;
  role: Role;
  state: UserState;
};

export type Result<T = any> = {
  code: number;
  errmsg: string;
  result?: T;
};

export type CCVideoType = {
  url: string;
  contentId: string;
  videoCheckToken?: number;
  height?: number;
  width?: number;
};
