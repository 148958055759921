// @ts-nocheck

  import SmileOutlined from '@ant-design/icons/es/icons/SmileOutlined';
import SettingOutlined from '@ant-design/icons/es/icons/SettingOutlined';
import RedditOutlined from '@ant-design/icons/es/icons/RedditOutlined';
import UserSwitchOutlined from '@ant-design/icons/es/icons/UserSwitchOutlined';
import VideoCameraOutlined from '@ant-design/icons/es/icons/VideoCameraOutlined';
import PlaySquareOutlined from '@ant-design/icons/es/icons/PlaySquareOutlined';
import MessageFilled from '@ant-design/icons/es/icons/MessageFilled';
import AccountBookOutlined from '@ant-design/icons/es/icons/AccountBookOutlined';
import PropertySafetyOutlined from '@ant-design/icons/es/icons/PropertySafetyOutlined';
import BarChartOutlined from '@ant-design/icons/es/icons/BarChartOutlined';
import LineChartOutlined from '@ant-design/icons/es/icons/LineChartOutlined';
import AreaChartOutlined from '@ant-design/icons/es/icons/AreaChartOutlined';
import PieChartOutlined from '@ant-design/icons/es/icons/PieChartOutlined'
  export default {
    SmileOutlined,
SettingOutlined,
RedditOutlined,
UserSwitchOutlined,
VideoCameraOutlined,
PlaySquareOutlined,
MessageFilled,
AccountBookOutlined,
PropertySafetyOutlined,
BarChartOutlined,
LineChartOutlined,
AreaChartOutlined,
PieChartOutlined
  }