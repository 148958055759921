export const roleOption = [
  {
    label: '超级管理员',
    value: 'MANAGER',
  },
  {
    label: '创作者',
    value: 'MERCHANT',
  },
  {
    label: '审核员',
    value: 'REVIEWER',
  },
  {
    label: '审核管理员',
    value: 'REVIEWER_MANAGER',
  },
  {
    label: '机构管理员',
    value: 'MERCHANT_MANAGER',
  },
];

// 机构管理员才有的权限
export const MERCHANT_MANAGER_OPTIONS = [
  {
    label: '创作者',
    value: 'MERCHANT',
  },
  {
    label: '机构管理员',
    value: 'MERCHANT_MANAGER',
  },
];

// 审核员才有的权限
export const REVIEWER_MANAGER_OPTIONS = [
  {
    label: '审核员',
    value: 'REVIEWER',
  },
  {
    label: '审核管理员',
    value: 'REVIEWER_MANAGER',
  },
];
