import { SESSION_USER_ID, SESSION_USER_NICK } from '@/constants';
import { CurrentUser } from '@/types';

/**
 * 保存登录用户相关信息到sessionStorage
 * Modal弹窗不再root根节点，无法获取@@initalState中的登录信息，所以保存一份在sessionStorage，先作为折中方案
 *
 * @param user
 */
export function setSessionUser(user: CurrentUser) {
  sessionStorage.setItem(SESSION_USER_ID, user?.id || '');
  sessionStorage.setItem(SESSION_USER_NICK, user?.merchantInfo?.nick || '');
}

/**
 * 删除用户信息
 */
export function removeSessionUser() {
  sessionStorage.removeItem(SESSION_USER_ID);
  sessionStorage.removeItem(SESSION_USER_NICK);
}

/**
 * 获取用户信息
 * @param type
 * @returns
 */
export function getSessionUserItem(type: 'id' | 'nick') {
  let name = '';

  switch (type) {
    case 'id':
      name = SESSION_USER_ID;
      break;
    case 'nick':
      name = SESSION_USER_NICK;
      break;
    default:
      name = '';
      break;
  }
  return sessionStorage.getItem(name);
}
