import { Settings as LayoutSettings } from '@ant-design/pro-components';

const Settings: LayoutSettings & {
  pwa?: boolean;
  logo?: string;
  siderWidth?: number;
} = {
  navTheme: 'light',
  // 拂晓蓝
  primaryColor: '#1890ff',
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title:
    process.env.UMI_APP_DEPLOY_ENV === 'production'
      ? '大宋制片厂管理后台'
      : '大宋制片厂管理后台',
  pwa: false,
  logo: 'https://ok.166.net/gameyw-misc/opd/squash/20191114/194825-w3p165zseh.png',
  iconfontUrl: '',
};

export default Settings;
